import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import type { CustomAxiosRequestConfig } from 'axios-auth-refresh/dist/utils';
import { navigate } from 'gatsby';
import {
  encodedUsername,
  destroyToken,
  setAuthToken,
  getAuthToken,
  setUserData,
  getUserData,
  destroyUserData,
  userDataAvailable,
} from './utils/authentication';
import { log } from './utils/debugHelper';
import { toSignIn } from './routes';
import { getGlobal } from 'reactn';
import types, {
  EmployerProfileAssessmentResponseViewType,
  EmployerProfileAssessmentViewType,
  JsonErrorType,
} from './types/services-api';
import { ResumeSearchParametersType } from './context/JobPostingContext';
import { JobSearchParamsType } from './context/JobSearchContext';

if (process.env.GATSBY_API_URL) {
  axios.defaults.baseURL = process.env.GATSBY_API_URL;
}

export const formatError = (error) => {
  let standardizedError = {
    message: '',
    data: null,
    ...error,
  };
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    log(`[api] error.response.data:`, error.response.data);
    log(`[api] error.response.status:`, error.response.status);
    log(`[api] error.response.headers:`, error.response.headers);
    standardizedError.data = error.response.data;
    standardizedError.message = `Error: The server responded with status code ${error.response.status}`;
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.errors &&
      error.response.data.error.errors.length > 0 &&
      error.response.data.error.errors[0].reason !== 'paymentError'
    ) {
      standardizedError.message = error.response.data.error.errors[0].message;
    } else if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message
    ) {
      standardizedError.message = error.response.data.error.message;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    log(`[api] error.request:`, error.request);
    standardizedError.message = `The request was made but no response was received`;
  } else {
    // Something happened in setting up the request that triggered an Error
    log('Error', error.message);
    standardizedError.message = `Something happened in setting up the request that triggered an error`;
  }
  // log(error.config);
  return standardizedError;
};

axios.interceptors.response.use(
  function (response) {
    const configData = {
      method: response.config.method,
      url: response.config.url,
      status: response.status,
      statusText: response.statusText,
    };
    log('[api]', configData);
    if (response.data) {
      log('[api]', response.data);
    }
    return response;
  },
  function (error) {
    return Promise.reject(formatError(error));
  }
);

// Since axios-auth-refresh automatically stalls additional requests while refreshing
// the token, it is a good idea to wrap your token logic interceptor in a
// function, to make sure the stalled requests are using the newly fetched token.
axios.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  const authToken = getAuthToken();
  if (!!authToken) {
    config.headers['Authorization'] = authToken;
  }
  return config;
});

const refreshAuthLogic = (failedRequest) => {
  log('attemptToRefreshToken() called');

  return Auth.refreshToken()
    .then((res) => {
      if (res.status === 201 && res.headers.authorization) {
        log('auth token refresh success');
        const token = res.headers.authorization;
        setAuthToken(token);
        failedRequest.response.config.headers['Authorization'] = token;

        return Promise.resolve();
      } else {
        console.error('auth token refresh failed', res);
        destroyToken();
        destroyUserData();
        navigate(toSignIn);
        return Promise.reject(res);
      }
    })
    .catch((err) => {
      console.error('error refreshing token', err.response);
      destroyToken();
      destroyUserData();
      navigate(toSignIn);
      return Promise.reject(err);
    });
};

// Instantiate the interceptor to handle automatically refreshing the token
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: false, // default: false
});

const customAxiosRequestConfig: CustomAxiosRequestConfig = {
  skipAuthRefresh: true,
};

/*
 * ------------------------------
 * Auth
 * ------------------------------
 */
const Auth = {
  login: function (body: {
    username: string | string[] | null;
    password: string | string[] | null;
    delegated: boolean;
  }) {
    return axios.post(
      `/api9/auth/login`,
      {
        ...body,
        legacy: false,
      },
      customAxiosRequestConfig
    );
  },
  refreshToken: function () {
    return axios.post(
      `/api9/auth/${encodedUsername()}/token`,
      undefined,
      customAxiosRequestConfig
    );
  },
  logout: function () {
    return axios
      .post(
        `/api9/auth/${encodedUsername()}/logout`,
        undefined,
        customAxiosRequestConfig
      )
      .then((res) => {
        destroyToken();
        destroyUserData();
        return res;
      })
      .catch((error) => {
        destroyToken();
        destroyUserData();
        return error;
      });
  },
  updateEmail: function (body: { email; password }) {
    return axios.put(`/api9/auth/${encodedUsername()}/email-address`, {
      ...body,
    });
  },
  updatePassword: function (body: {
    oldPassword: string;
    password: string;
    confirmationPassword: string;
  }) {
    return axios.post(`/api9/auth/${encodedUsername()}/password`, {
      ...body,
    });
  },
  requestPasswordReset: function (body: { email: string; role: string }) {
    return axios.put(`/api9/auth/password`, {
      ...body,
    });
  },
  resetPasswordWithResetCode: function (body: {
    email: string;
    role: string;
    resetCode: string;
    newPassword: string;
  }) {
    return axios.post(`/api9/auth/password`, {
      ...body,
    });
  },
};

/*
 * ------------------------------
 * Staff
 * ------------------------------
 */
const Staff = {
  register: function (
    body: types.RegistrationStaffViewType & {
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmContent?: string;
    }
  ) {
    return axios.post<types.RegistrationStaffViewType>(
      `/api9/users/staff`,
      body
    );
  },
  current: function () {
    return axios
      .get<types.StaffProfileViewType>(`/api9/users/staff/${encodedUsername()}`)
      .then((res) => {
        if (res.data) {
          const userData = {
            userId: res.data.id,
            name: res.data.name,
            email: res.data.email,
          };
          setUserData(userData);
        }
        return res;
      });
  },
  profile: function () {
    return axios.get<types.StaffResumeViewType>(
      `/api9/users/staff/${encodedUsername()}/resumes`
    );
  },
  applications: function () {
    return axios.get<types.JobApplicationViewType[]>(
      `/api9/users/staff/${encodedUsername()}/applications`
    );
  },
  invitations: function () {
    return axios.get<
      (types.JobApplicationViewType & types.JobInvitationViewType)[]
    >(`/api9/users/staff/${encodedUsername()}/invitations`);
  },
  getInvitation: function (invitationId: number) {
    return axios.get<
      types.JobApplicationViewType & types.JobInvitationViewType
    >(`/api9/users/staff/${encodedUsername()}/invitations/${invitationId}`);
  },
  declineInvitation: function (jobId: number, message: string) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/invitations/${jobId}`,
      { message }
    );
  },
  applyToJob: function ({ jobId, jobDates }) {
    return axios.post(
      `/api9/users/staff/${encodedUsername()}/applications/${jobId}`,
      { jobDates }
    );
  },
  getApplication: function ({ jobId }) {
    return axios.get<types.JobApplicationViewType>(
      `/api9/users/staff/${encodedUsername()}/applications/${jobId}`
    );
  },
  updateBasicInfo: function ({ body }) {
    return axios.put(`/api9/users/staff/${encodedUsername()}/basic-info`, body);
  },
  updateLocationInfo: function ({ body }) {
    return axios.put<types.StaffProfileViewType>(
      `/api9/users/staff/${encodedUsername()}/location-info`,
      body
    );
  },
  updateEmailAlerts: function ({ emailAlerts, radius }) {
    return axios.put(`/api9/users/staff/${encodedUsername()}/email-alerts`, {
      emailAlerts,
      radius,
    });
  },
  updateIntro: function ({ body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/intro`,
      body
    );
  },
  updateOther: function ({ body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/other`,
      body
    );
  },
  updateWorkExperience: function ({ body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/experience`,
      body
    );
  },
  createCompany: function ({ body }) {
    return axios.post(
      `/api9/users/staff/${encodedUsername()}/resumes/companies`,
      body
    );
  },
  updateCompany: function ({ companyId, body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/companies/${companyId}`,
      body
    );
  },
  deleteCompany: function ({ companyId }) {
    return axios.delete(
      `/api9/users/staff/${encodedUsername()}/resumes/companies/${companyId}`
    );
  },
  createCourse: function ({ body }) {
    return axios.post(
      `/api9/users/staff/${encodedUsername()}/resumes/courses`,
      body
    );
  },
  updateCourse: function ({ courseId, body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/courses/${courseId}`,
      body
    );
  },
  deleteCourse: function ({ courseId }) {
    return axios.delete(
      `/api9/users/staff/${encodedUsername()}/resumes/courses/${courseId}`
    );
  },
  createSchool: function ({ body }) {
    return axios.post(
      `/api9/users/staff/${encodedUsername()}/resumes/schools`,
      body
    );
  },
  updateSchool: function ({ schoolId, body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/schools/${schoolId}`,
      body
    );
  },
  deleteSchool: function ({ schoolId }) {
    return axios.delete(
      `/api9/users/staff/${encodedUsername()}/resumes/schools/${schoolId}`
    );
  },
  createCert: function ({ body }) {
    return axios.post(
      `/api9/users/staff/${encodedUsername()}/resumes/certs`,
      body
    );
  },
  updateCert: function ({ certId, body }) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/certs/${certId}`,
      body
    );
  },
  deleteCert: function ({ certId }) {
    return axios.delete(
      `/api9/users/staff/${encodedUsername()}/resumes/certs/${certId}`
    );
  },
  getStaffPremiumData: function () {
    return axios.get<types.UserPremiumInfoViewType>(
      `/api9/users/staff/${encodedUsername()}/resumes/premium-info`
    );
  },
  updateVisibility: function (body) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/visibility`,
      body
    );
  },
  updateAvailability: function (body) {
    return axios.put(
      `/api9/users/staff/${encodedUsername()}/resumes/availability`,
      body
    );
  },
};

/*
 * ------------------------------
 * Employer
 * ------------------------------
 */

const Employer = {
  register: function (
    body: types.RegistrationEmployerViewType & {
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmContent?: string;
    }
  ) {
    return axios.post(`/api9/users/employers`, { ...body });
  },
  current: function () {
    return axios
      .get<types.UserEmployerViewType>(
        `/api9/users/employers/${encodedUsername()}`
      )
      .then((res: { data: types.UserEmployerViewType }) => {
        if (res.data) {
          const userData = {
            userId: res.data.id,
            name: res.data.name,
            email: res.data.email,
          };
          setUserData(userData);
        }
        return res;
      });
  },
  jobs: function () {
    return axios.get<types.JobOwnerSummaryViewType[]>(
      `/api9/users/employers/${encodedUsername()}/jobs?limit=2500`
    );
  },
  subscription: function (orderId: number) {
    return axios.get<types.ProductOrderViewType>(
      `/api9/users/employers/${encodedUsername()}/order/${orderId}`
    );
  },
  jobSlots: function () {
    return axios.get<types.JobSlotType[]>(
      `/api9/users/employers/${encodedUsername()}/job-slots`
    );
  },
  cancelSlot: function (slotId: number) {
    return axios.get<types.JobSlotType[]>(
      `/api9/users/employers/${encodedUsername()}/job-slot/${slotId}/cancel`
    );
  },
  inviteToApply: function (props: { signedUrl: string; message: string }) {
    return axios.post<types.InvitationResumeViewType>(`${props.signedUrl}`, {
      message: props.message,
    });
  },
  cancelInvite: function ({ jobId, username }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/invitations/${encodedUsername(
        username
      )}`
    );
  },
  profile: function () {
    return axios.get<types.EmployerProfileViewType>(
      `/api9/users/employers/${encodedUsername()}/profile`
    );
  },
  offices: function () {
    return axios.get<types.OfficeProfileViewType[]>(
      `/api9/users/employers/${encodedUsername()}/offices`
    );
  },
  officeDetail: function ({ officeId }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}`
    );
  },
  createOffice: function ({ body }) {
    return axios.post(
      `/api9/users/employers/${encodedUsername()}/offices`,
      body
    );
  },
  updateOffice: function ({ officeId, body }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}`,
      body
    );
  },
  updatePrimaryOffice: function ({ officeId }) {
    return axios.put<types.OfficeProfileViewType>(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}/primary`
    );
  },
  deleteOffice: function ({ officeId }) {
    return axios.delete(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}`
    );
  },
  defaultOfficePhoto: function () {
    const userData = getUserData();
    return (
      process.env.GATSBY_API_URL +
      `api9/photos/multi/${
        !!userData ? userData.userId : undefined
      }/OFFICE_PHOTOS/0/0`
    );
  },
  uploadOfficePhoto: function (props: {
    officeId: number;
    description: string;
    photoFile: Blob;
  }) {
    const userData = getUserData();
    const body = new FormData();
    body.append('description', props.description);
    body.append('file', props.photoFile);
    return axios.post(
      `/api9/photos/multi/${
        !!userData ? userData.userId : undefined
      }/OFFICE_PHOTOS/${props.officeId}`,
      body
    );
  },
  editOfficePhoto: function ({ signedPhotoUrl, description, photoFile }) {
    const body = new FormData();
    body.append('description', description);
    body.append('file', photoFile);
    return axios.put(signedPhotoUrl, body);
  },
  deleteOfficePhoto: function ({ officeId, photoId }) {
    const userData = getUserData();
    return axios.delete(
      `/api9/photos/multi/${
        !!userData ? userData.userId : undefined
      }/OFFICE_PHOTOS/${officeId}/${photoId}`
    );
  },
  sortOfficePhotos: function ({ officeId, ids }) {
    const userData = getUserData();
    if (userDataAvailable(userData)) {
      const { userId } = userData;
      return axios.put(
        `/api9/photos/multi/${userId}/OFFICE_PHOTOS/${officeId}/order`,
        { ids: ids }
      );
    }
  },
  insertOfficeHours: function ({ officeId, body }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}/hours`,
      body
    );
  },
  deleteOfficeHours: function ({ officeId, dow }) {
    return axios.delete(
      `/api9/users/employers/${encodedUsername()}/offices/${officeId}/hours/${dow}`
    );
  },
  updateName: function ({ body }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/contact-info`,
      body
    );
  },
  updatePublic: function ({ body }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/profile/public`,
      body
    );
  },
  updateAbout: function ({ about }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/profile/about`,
      {
        about,
      }
    );
  },
  updateCandidateInfo: function ({ state }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/profile/candidate`,
      {
        state,
      }
    );
  },
  applications: function () {
    return axios.get(`/api9/users/employers/${encodedUsername()}/applications`);
  },
  receipts: function () {
    return axios.get<types.PaymentReceiptType[]>(
      `/api9/users/employers/${encodedUsername()}/payment/receipts`
    );
  },
  getJob: function (jobId: number) {
    return axios.get<types.JobOwnerDetailViewType>(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}`
    );
  },
  publishJob: function (jobId: number, slotId?: number) {
    return axios.put<types.JobOwnerDetailViewType>(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/publish${
        !!slotId ? `/?slot-id=${slotId}` : ''
      }`
    );
  },
  updateJob: function (props: {
    jobId: number;
    body: Partial<types.JobOwnerDetailViewType> | { jobDates: string[] };
  }) {
    return axios.put<types.JobOwnerDetailViewType>(
      `/api9/users/employers/${encodedUsername()}/jobs/${props.jobId}`,
      props.body
    );
  },
  subscriptions: function () {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/subscriptions`
    );
  },
  matching: function () {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/match-criteria`
    );
  },
  getCoupon: function ({ sku, couponCode }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/coupons/${sku}/${couponCode}`
    );
  },
  applyCoupon: function ({ zip, position, category, couponCode }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/catalog/${zip}/${position}/${category}?coupon-code=${couponCode}`
    );
  },
  upgradeResume: function ({ zip, position }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/catalog/${zip}/${position}/UPGRADE_RESUME`
    );
  },
  paymentToken: function () {
    return axios.get<types.PaymentTokenViewType>(
      `/api9/users/${encodedUsername()}/payment/token`
    );
  },
  getReceipt: function (paymentId: string) {
    return axios.get<types.PaymentReceiptType>(
      `/api9/users/employers/${encodedUsername()}/payment/${paymentId}/receipt`
    );
  },
  getInterviewOutcome: function ({ interviewUrl }) {
    return axios.get<types.UserInterviewViewType>(interviewUrl);
  },
  updateInterviewOutcome: function ({ interviewUrl, body }) {
    return axios.put<types.UserInterviewViewType>(interviewUrl, body);
  },
};

/*
 * ------------------------------
 * User
 * ------------------------------
 */
const User = {
  updateEmailOptouts: function (optouts) {
    return axios.put(`/api9/users/${encodedUsername()}/optout2`, {
      ...optouts,
    });
  },
  generateLead: function (userData) {
    return axios.put(`/api9/users/public/lead`, { ...userData });
  },
  uploadProfilePhoto: function ({ signedPhotoUrl, photoFile }) {
    const body = new FormData();
    body.append('file', photoFile);
    return axios.post(signedPhotoUrl, body);
  },
  editProfilePhoto: function ({ signedPhotoUrl, photoFile }) {
    const body = new FormData();
    body.append('file', photoFile);
    return axios.put(signedPhotoUrl, body);
  },
  deleteProfilePhoto: function ({ signedPhotoUrl }) {
    return axios.delete(signedPhotoUrl);
  },
  getEmployer: function ({ employer }) {
    return axios.get(
      `/api9/users/${encodedUsername()}/employer/${encodedUsername(employer)}`
    );
  },
  getInterviews: function ({ date }) {
    return axios.get<types.UserInterviewViewType[]>(
      `/api9/users/${encodedUsername()}/interviews`,
      {
        params: { date },
      }
    );
  },
  // getInterviews: function ({ date }) {
  //   return axios.get<
  //     | ServicesApiTypes.EmployerInterviewResponseType[]
  //     | ServicesApiTypes.StaffInterviewResponseType[]
  //   >(`/api9/users/${encodedUsername()}/interviews`, { params: { date } });
  // },
};
/*
 * ------------------------------
 * Jobs
 * ------------------------------
 */

const Jobs = {
  getDiscountCode: function (props: { product: string; discountCode: string }) {
    return axios.get(
      `/api9/prepay/discounts/${props.product}/${props.discountCode}`
    );
  },
  catalog: function (discountCode?: string) {
    return axios.get<types.CatalogViewType>(
      `/api9/users/employers/${encodedUsername()}/catalog`,
      {
        params: {
          'discount-code': discountCode,
        },
      }
    );
  },
  publicCatalog: function (discountCode?: string) {
    return axios.get<types.CatalogViewType>(`/api9/prepay/catalog`, {
      params: {
        'discount-code': discountCode,
      },
    });
  },
  paymentToken: function () {
    return axios.get<types.PaymentTokenViewType>(`/api9/prepay/token`);
  },
  prepay: function (body: {
    email: string;
    name: string;
    nonce: string;
    phone: string;
    discountCode?: string;
    products: { productCode: string; quantity: number; discountCode: string }[];
    fee: number;
    nextFee?: number;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmContent?: string;
  }) {
    const params = !!body.discountCode
      ? {
          params: {
            'discount-code': body.discountCode,
          },
        }
      : undefined;
    const api = getGlobal().isAuthenticated
      ? `/api9/users/employers/${encodedUsername()}/payment`
      : `/api9/prepay/payment`;
    return axios.post<
      types.PaymentResultsViewType | { data: { error: JsonErrorType } }
    >(
      api,
      {
        ...body,
      },
      params
    );
  },
  addOnPayment: function (body: {
    email: string;
    name: string;
    nonce: string;
    phone: string;
    discountCode?: string;
    jobId: number;
    products: { productCode: string; quantity: number; discountCode: string }[];
    fee: number;
    nextFee?: number;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmContent?: string;
  }) {
    const { jobId } = body;
    const params = !!body.discountCode
      ? {
          params: {
            'discount-code': body.discountCode,
          },
        }
      : undefined;
    const api = `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/payment`;
    return axios.post<types.PaymentResultsViewType>(
      api,
      {
        ...body,
      },
      params
    );
  },
  editSubscription: function ({ orderId, body }) {
    return axios.put<types.PaymentResultsViewType>(
      `/api9/users/employers/${encodedUsername()}/order/${orderId}`,
      body
    );
  },
  create: function (
    body: Partial<types.JobOwnerDetailViewType> | { jobDates: string[] }
  ) {
    return axios.post<types.JobOwnerDetailViewType>(
      `/api9/users/employers/${encodedUsername()}/jobs`,
      body
    );
  },
  get: function ({ jobId }) {
    return axios.get<
      types.JobInternalViewType | types.PartnerJobDetailViewType
    >(`/api9/users/${encodedUsername()}/jobs/${jobId}`);
  },
  applications: function (props: { jobId: number; matching: boolean }) {
    return axios.get<types.EmployerAppSummaryViewType[]>(
      `/api9/users/employers/${encodedUsername()}/applications/${props.jobId}${
        props.matching ? '/matches' : ''
      }`
    );
  },
  invitations: function ({ jobId }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/invitations`
    );
  },
  getJobApplicationDetail: function ({ jobId, applicantUsername, matchId }) {
    //when iterable encodes a username, there's no need to encode when calling the api
    const username = applicantUsername.includes('%')
      ? applicantUsername
      : encodedUsername(applicantUsername);
    return axios.get<types.EmployerResumeViewType>(
      `/api9/users/employers/${encodedUsername()}/applications/${jobId}/${username}`,
      {
        params: {
          match: matchId,
        },
      }
    );
  },
  search: function (searchParams: JobSearchParamsType) {
    return axios.get<
      types.JobResponseViewType<
        types.JobInternalViewType[],
        types.PartnerJobDetailViewType[]
      >
    >(`/api9/users/${encodedUsername()}/jobs`, { params: searchParams });
  },
  getPublic: function ({ jobId }) {
    return axios.get<
      types.JobResponseViewType<
        types.JobViewType[],
        types.PartnerJobDetailViewType[]
      >
    >(`/api9/jobs/${jobId}`);
  },
  redeem: function ({ jobId, productCode }) {
    return axios.post(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/redeem`,
      {
        product: productCode,
      }
    );
  },
  view: function (props: { jobId: string | number; body?: {} }) {
    if (getGlobal().isAuthenticated) {
      return axios.put(
        `/api9/users/${encodedUsername()}/jobs/${props.jobId}/view`,
        props.body
      );
    } else {
      return axios.put(`/api9/jobs/${props.jobId}/view`, props.body);
    }
  },
  updateOpinion: function ({ jobId, body }) {
    return axios.put<types.JobInternalViewType>(
      `/api9/users/${encodedUsername()}/jobs/${jobId}/opinion`,
      body
    );
  },
  updateJobStatus: function ({ jobId, body }) {
    return axios.put<types.JobOwnerDetailViewType>(
      `/api9/users/employers/${encodedUsername()}/jobs/${jobId}/status`,
      body
    );
  },
  updateJobDateStatus: function (props: {
    jobId: number;
    jobDateId;
    body: { status: string };
  }) {
    return axios.put<{ jobDates: types.JobDateType[] }>(
      `/api9/users/employers/${encodedUsername()}/jobs/${props.jobId}/status/${
        props.jobDateId
      }`,
      props.body
    );
  },
  getUserJobFlag: function ({ jobId }) {
    return axios.get(`/api9/users/${encodedUsername()}/jobs/${jobId}/flag/`);
  },
  updateUserJobFlag: function ({ jobId, body }) {
    return axios.put(
      `/api9/users/${encodedUsername()}/jobs/${jobId}/flag`,
      body
    );
  },
};
/*
 * ------------------------------
 * Resumes
 * ------------------------------
 */
const Resumes = {
  search: function (props: {
    jobId: number;
    searchParams: ResumeSearchParametersType;
    preview?: boolean;
  }) {
    const { jobId, searchParams, preview } = props;
    return axios.get<types.EmployerResumeResponseViewType>(
      `/api9/users/employers/${encodedUsername()}/resumes/${jobId}/search`,
      {
        params: {
          position: searchParams.position,
          zip: searchParams.zip,
          limit: searchParams.limit.toString(),
          offset: searchParams.offset.toString(),
          dow: searchParams.dow,
          radius: searchParams.radius,
          keyword: searchParams.keyword,
          type: searchParams.type,
          evaluation: searchParams.evaluation,
          photo: searchParams.photo,
          disc: searchParams.disc,
          values: searchParams.values,
          skills: searchParams.skills,
          culture: searchParams.culture,
          preview: !!preview ? 1 : 0,
        },
      }
    );
  },
  getResumeDetailValidated: function ({ validatedUrl }) {
    return axios.get(validatedUrl);
  },
  purchase: function ({
    nonce,
    productCode,
    positionCode,
    zip,
    redeemWithPoints,
    couponCode,
    campaignId,
    templateId,
    jobId,
  }) {
    return axios.post(
      `/api9/users/employers/${encodedUsername()}/subscriptions`,
      {
        nonce,
        product: productCode,
        position: positionCode,
        zip,
        redeemWithPoints,
        upgradedId: jobId,
      },
      {
        params: {
          'coupon-code': couponCode,
          'campaign-id': campaignId,
          'template-id': templateId,
        },
      }
    );
  },
  addFeedbackLabel: function ({ feedbackUrl, feedback }) {
    return axios.post(feedbackUrl, { feedback });
  },
  removeFeedbackLabel: function ({ feedbackUrl, feedback }) {
    return axios.delete(feedbackUrl, { data: { feedback } });
  },
  updateFeedbackNote: function ({ resumeUsername, note }) {
    return axios.put(
      `/api9/users/employers/${encodedUsername()}/resumes/${encodedUsername(
        resumeUsername
      )}/feedback-notes`,
      { note }
    );
  },
  updateOpinion: function ({ resumeUsername, opinion }) {
    return axios.put<types.OpinionUpdateViewType>(
      `/api9/users/employers/${encodedUsername()}/resumes/${encodedUsername(
        resumeUsername
      )}/opinion`,
      { ...opinion }
    );
  },
};

/*
 * ------------------------------
 * Candidate Matching
 * ------------------------------
 */
const CandidateMatching = {
  get: function ({ jobId }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/match-criteria/${jobId}`
    );
  },
  create: function ({ jobId, criteria }) {
    return axios.post<types.MatchCriteriaType>(
      `/api9/users/employers/${encodedUsername()}/match-criteria/${jobId}`,
      criteria
    );
  },
  update: function ({ jobId, criteria }) {
    return axios.put<types.MatchCriteriaType>(
      `/api9/users/employers/${encodedUsername()}/match-criteria/${jobId}`,
      criteria
    );
  },
  search: function (props: {
    jobId: number;
    searchParams: ResumeSearchParametersType;
    preview?: boolean;
  }) {
    return axios.get(
      `/api9/users/employers/${encodedUsername()}/resumes/${
        props.jobId
      }/matches`,
      { params: { ...props.searchParams, preview: !!props.preview ? 1 : 0 } }
    );
  },
};
/*
 * ------------------------------
 * Conversations
 * ------------------------------
 */
const Conversations = {
  get: function ({ limit, offset }) {
    return axios.get<types.UserConversationViewType[]>(
      `/api9/users/${encodedUsername()}/conversations`,
      {
        params: {
          limit,
          offset,
        },
      }
    );
  },
  getById: function ({ conversationId }) {
    return axios.get<types.UserConversationViewType>(
      `/api9/users/${encodedUsername()}/conversations/${conversationId}`
    );
  },
  getMessages: function ({ conversationId }) {
    return axios.get<types.UserMessageType[]>(
      `/api9/users/${encodedUsername()}/conversations/${conversationId}/messages`
    );
  },
  sendMessage: function ({ conversationId, message }) {
    return axios.post(
      `/api9/users/${encodedUsername()}/conversations/${conversationId}/messages`,
      { message }
    );
  },
  markAsRead: function ({ conversationId, readStatus }) {
    return axios.put(
      `/api9/users/${encodedUsername()}/conversations/${conversationId}/read`,
      {
        read: readStatus,
      }
    );
  },
  getChangeset: function () {
    return axios.get<types.UserConversationChangeSetViewType>(
      `/api9/users/${encodedUsername()}/conversations/changeset`
    );
  },
};

const Classifieds = {
  get: function () {
    return axios.get(`/api9/classifieds/${encodedUsername()}`);
  },
  create: function ({ listingData }) {
    return axios.post<types.ClassifiedViewType>(
      `/api9/classifieds/${encodedUsername()}`,
      {
        ...listingData,
      }
    );
  },
  postAgain: function ({ classifiedId }) {
    return axios.post(`/api9/classifieds/${encodedUsername()}/${classifiedId}`);
  },
  update: function ({ classifiedId, listingData }) {
    return axios.put(`/api9/classifieds/${encodedUsername()}/${classifiedId}`, {
      ...listingData,
    });
  },
  uploadPrimaryPhoto: function (classifiedId: number, photoFile: Blob) {
    const userData = getUserData();
    const body = new FormData();
    body.append('file', photoFile);
    return axios.post(
      `/api9/photos/docs/${userData?.userId}/CLASSIFIED/${classifiedId}`,
      body
    );
  },

  createAdditionalPhotoDescription: function ({ classifiedId, description }) {
    return axios.post(
      `/api9/classifieds/${encodedUsername()}/${classifiedId}/photos`,
      { description }
    );
  },

  uploadAdditionalPhoto: function ({ signedPhotoUrl, photoFile }) {
    const body = new FormData();
    body.append('file', photoFile);
    return axios.post<types.UserImageViewType>(signedPhotoUrl, body);
  },

  getAdditionalPhotos: function (classifiedId: number) {
    return axios.get<types.ClassifiedPhotoViewType[]>(
      `/api9/classifieds/${encodedUsername()}/${classifiedId}/photos`
    );
  },

  deleteAdditionalPhoto: function ({ listingId, photoId }) {
    return axios.delete(
      `/api9/classifieds/${encodedUsername()}/${listingId}/photos/${photoId}`
    );
  },

  getPublic: function ({ classifiedId }) {
    return axios.get(`/api9/classifieds/public/${classifiedId}`);
  },
  getPublicPhotos: function ({ classifiedId }) {
    return axios.get(`/api9/classifieds/public/photos/${classifiedId}`);
  },
};

const Events = {
  create: function ({ body }) {
    return axios.post(`/api9/events/${encodedUsername()}`, { ...body });
  },
  getUserEvents: function () {
    return axios.get<types.EventViewType[]>(
      `/api9/events/${encodedUsername()}`
    );
  },
};

/*
 * ------------------------------
 * Assessments
 * ------------------------------
 */
const Assessments = {
  getAlignment: function () {
    return axios.get(`/api9/users/${encodedUsername()}/alignment`);
  },
  updateAlignment: function ({ personalVisionAnswer, teamExpectationsAnswer }) {
    return axios.put(`/api9/users/${encodedUsername()}/alignment`, {
      personalVisionAnswer,
      teamExpectationsAnswer,
    });
  },
  deleteAlignment: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/alignment`);
  },

  getDisc: function () {
    return axios.get(`/api9/users/${encodedUsername()}/disc`);
  },
  updateDisc: function ({ assessmentResponses }) {
    // ex. [["I", "S", "C", "D"], ["D", "S", "I", C]]
    const discAnswers = assessmentResponses.map((question) =>
      question.map((q) => q.answer)
    );
    return axios.put(`/api9/users/${encodedUsername()}/disc`, discAnswers);
  },
  getEmotionalIntelligence: function () {
    return axios.get(`/api9/users/${encodedUsername()}/emotions`);
  },
  deleteDisc: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/disc`);
  },
  updateEmotionalIntelligence: function ({ questionIdAnswers }) {
    return axios.put(`/api9/users/${encodedUsername()}/emotions`, {
      questionIdAnswers,
    });
  },
  deleteEmotionalIntelligence: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/emotions`);
  },
  getCulture: function () {
    return axios.get(`/api9/users/${encodedUsername()}/culture`);
  },
  getEmployerCulture: function () {
    return axios.get(`/api9/users/${encodedUsername()}/culture-employer`);
  },
  updateCulture: function ({ multipleChoiceAnswers, preferenceAnswers }) {
    return axios.put(`/api9/users/${encodedUsername()}/culture`, {
      multipleChoiceAnswers,
      preferenceAnswers,
    });
  },
  updateEmployerCulture: function ({ multipleChoiceAnswers }) {
    return axios.put(`/api9/users/${encodedUsername()}/culture-employer`, {
      multipleChoiceAnswers,
    });
  },
  deleteCulture: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/culture`);
  },
  deleteEmployerCulture: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/culture-employer`);
  },
  getSatisfaction: function () {
    return axios.get(`/api9/users/${encodedUsername()}/satisfaction`);
  },
  updateSatisfaction: function (assessmentResponses) {
    return axios.put(
      `/api9/users/${encodedUsername()}/satisfaction`,
      assessmentResponses
    );
  },
  deleteSatisfaction: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/satisfaction`);
  },

  getValues: function () {
    return axios.get(`/api9/users/${encodedUsername()}/values`);
  },
  updateValues: function (values: string[]) {
    return axios.put(`/api9/users/${encodedUsername()}/values`, { values });
  },
  deleteValues: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/values`);
  },

  getSkills: function () {
    return axios.get(`/api9/users/${encodedUsername()}/skills`);
  },
  updateSkills: function ({ skills }) {
    return axios.put(`/api9/users/${encodedUsername()}/skills`, {
      skills,
    });
  },
  deleteSkills: function () {
    return axios.delete(`/api9/users/${encodedUsername()}/skills`);
  },
  updateOnboardingSurvey: function (props: EmployerProfileAssessmentViewType) {
    return axios.put<EmployerProfileAssessmentResponseViewType>(
      `/api9/users/employers/${encodedUsername()}/profile/assessment`,
      props
    );
  },
  deleteOnboardingSurvey: function () {
    return axios.delete(
      `/api9/users/employers/${encodedUsername()}/profile/assessment`
    );
  },
};

/*
 * ------------------------------
 * Contact
 * ------------------------------
 */
const Contact = {
  submit: function ({ body }) {
    if (getGlobal().isAuthenticated) {
      return axios.post(`/api9/contactus/${encodedUsername()}`, body);
    } else {
      return axios.post(`/api9/contactus`, body);
    }
  },
};

/*
 * ------------------------------
 * Lists
 * ------------------------------
 */
const Lists = {
  getDiscQuestions: function () {
    return axios.get(`/api9/lists/disc-questions`);
  },

  getEmotionalIntelligenceQuestions: function () {
    return axios.get(`/api9/lists/emotional-intelligence-questions`);
  },

  getCoreValues: function () {
    return axios.get<types.ValuesAssessmentType>(`/api9/lists/values`);
  },

  getCultureMultipleChoiceQuestions: function () {
    return axios.get(`/api9/lists/culture-multiple-choice-questions`);
  },

  getCulturePreferenceQuestions: function () {
    return axios.get(`/api9/lists/culture-preference-questions`);
  },

  getEmployerCultureMultipleChoiceQuestions: function () {
    return axios.get(`/api9/lists/employer-culture-multiple-choice-questions`);
  },

  getSatisfactionQuestions: function () {
    return axios.get(`/api9/lists/job-satisfaction-questions`);
  },

  getSkillCategories: function () {
    return axios.get(`/api9/lists/skill-cats`);
  },

  getSkills: function () {
    return axios.get(`/api9/lists/skills`);
  },

  getEmployerSkillCategories: function () {
    return axios.get(`/api9/lists/skill-cats-employer`);
  },

  getEmployerSkills: function () {
    return axios.get<types.SkillType[]>(`/api9/lists/skills-employer`);
  },
  getCandidateSkills: function () {
    return axios.get(`/api9/lists/skills-staff`);
  },
  getCandidateTypeQuestions: function () {
    return axios.get<
      { question: string; choices: { phrase: string; answer: string }[] }[]
    >(`/api9/lists/match-questions`);
  },
};

export default {
  Assessments,
  Auth,
  Classifieds,
  Contact,
  Conversations,
  Employer,
  Events,
  User,
  Jobs,
  Lists,
  Resumes,
  CandidateMatching,
  Staff,
};
